import React, { useState, useEffect} from "react";
import Sidebar from "../../components/sidebar";
import Card from "react-bootstrap/Card";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as FaIcons from "react-icons/fa";
import Cargando from "../../components/cargando";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';


const url = "https://apibacked.cooltask.homes/public/depositos";


const Almacen = () => {

  //////////////Datos de Usuario Logueado/////////////////////////
  const [users, setUsers] = useState([]);

  const [idd, setidd] = useState(null);
  const idtrabajador = users.id;
  const nombret = users.username; 
 

  useEffect(() => {
    const loginUserJSON = window.localStorage.getItem('loginUser')
    if(loginUserJSON){
      const user= JSON.parse(loginUserJSON)
      setUsers(user);
    }
   }, []);
    

  //////////////Iniciando Status data//////////////////////////
  const [data, setData] = useState([]);

  const [datosSeleccionados, setDatosSeleccionados] = useState({
    id: '',
   fecha: '',
    idusuario: '',
    cantidad: '',
    walletorigen: '',
    txid: '',
    status: '',
    idtrabajador:'',
    nombretrabajador:'',
  });

  /////////////////Datatable/////////////////////////////////

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const [pending, setPending] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(data);
      setPending(false);
    }, 100);
    return () => clearTimeout(timeout);
  });

  const columns = [
    {
      name: 'Accion',
      button: true,
      cell: (row) =>
        <button className="btn btn-info"
          onClick={() => { setDatosSeleccionados(row); abrirModalEditar() }} >
          <FaIcons.FaMedrt />
        </button>,
    },
    {
      name: 'Id',
      selector: (row) => row.id,
      defaultSortAsc: true,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: (row) => row.fecha,
    },
    {
      name: 'Hora',
      selector: (row) => row.hora,
    },
    {
      name: 'Correo',
      selector: (row) => row.correo,
    },
    {
      name: 'Cantidad',
      selector: (row) => row.cantidad,
    },
    {
      name: 'status',
      selector: (row) => row.status,
    }
  ];
  /////////////Buscar datos//////////////////////////////////
  const [buscar, setBuscar] = useState("");
  const [filtrobuscar, setFiltroBuscar] = useState([]);

  useEffect(() => {
    const result = data.filter((resultdata) => {
      if(resultdata.correo.toLowerCase().match(buscar.toLowerCase())
      ){
        return resultdata;
      }
    });
    setFiltroBuscar(result);
  }, [buscar, data]);



  /////////////Mostrar datos//////////////////////////////////
  const peticionGet = async () => {
    await axios.get(url).then(response => {
      console.log(response.data);
      setData(response.data);
      setFiltroBuscar(response.data);
      console.log(response.data);
    })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await peticionGet();
  }, []);


  ///////////////Insertar Datos//////////////////////////////////
  const [modalInsertar, setModalInsertar] = useState(false);
 

  const handleChange = e => {
    const { name, value } = e.target;
    setDatosSeleccionados(prevState => ({
      ...prevState,
      [name]: value
    }))

  }

  const [Error, setError] = useState(false);

  const peticionPost = async () => {
    delete datosSeleccionados.id;
    console.log(datosSeleccionados);
    await axios.post(url,datosSeleccionados).then(res => {
      peticionGet();
      setError(false);
      setModalInsertar(false);
    })
      .catch((err) => {
        setError(true);
        setModalInsertar(false);
      })
  }

  //////////////Editar datos/////////////////////////////////////
  const [modalEditar, setModalEditar] = useState(false);
  const abrirModalEditar = () => {
    console.log(datosSeleccionados);
    setModalEditar(true);
  }

  const peticionPut = async () => {
    console.log(idd);
    console.log(idtrabajador);
    console.log(nombret);
    await axios.put(url+'/'+datosSeleccionados.id,{idd,idtrabajador,nombret}).then(response => {
      peticionGet();
      setError(false);
      setModalEditar(false);
    })
      .catch((err) => {
        console.log(err);
        setError(true);
        setModalEditar(false);
      })
  }

  console.log(users.id);
  console.log(users.username);
  /////////////////////////////////////////////////////////////////

        return (
          <div>
            <div className="Almacen">
              <Sidebar>
                <Card>
                  <Card.Header className="bg-dark text-white" as="h3">
                    <div className='row'>
                      <div className='col-md-6'>
                        Reclamar Depositos
                      </div>
                      
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>Depositos registrados</Card.Title>
                    {
                      Error &&
                      <div className="alert alert-danger">
                        {<strong>Error: El nombre ya existe! intente con otro nombre.</strong>}
                      </div>
                    }
                    <p>Podras reclamar los depositos para luego procesarlos en la seccion "Tus depositos".</p>
                    <DataTable
                      columns={columns}
                      data={filtrobuscar}
                      noDataComponent="No hay elementos a mostrar"
                      progressPending={pending}
                      progressComponent={<Cargando />}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                      subHeader
                      subHeaderComponent={
                        <input
                          type="text"
                          placeholder="Buscar"
                          className="w-25 form-control"
                          value={buscar}
                          onChange={(e) => setBuscar(e.target.value)}
                        />
                      }
                    />
      
                    <Modal isOpen={modalInsertar}>
                      <ModalHeader>
                        <div>
                          <h4>Insertar Almacen</h4>
                        </div>
                      </ModalHeader>
                      <ModalBody>
                        <div className="form-group">
                          <label>Almacen</label>
                          <input
                            className="form-control"
                            type="text"
                            name="nombre"
                            required=""
                            onChange={handleChange}
                          />
                          <br />
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <button
                          className="btn btn-dark"
                          onClick={() => setModalInsertar(false)}
                        >
                          Cancelar
                        </button>
                        <button className="btn btn-success"
                          onClick={() => peticionPost()}>
                          Insertar
                        </button>
      
                      </ModalFooter>
                    </Modal>
      
                    <Modal isOpen={modalEditar}>
                      <ModalHeader>
                        <div>
                          <h4>Reclamar deposito</h4>
                        </div>
                      </ModalHeader>
                      <ModalBody>
                          <input
                            className="form-control" readOnly
                            type="hidden"
                            name="id"
                            value={datosSeleccionados.id}
                            onChange={setidd}
                          />
                           
                        <div className="form-group">
                          <h5>¿Desea reclamar el deposito #{datosSeleccionados.id} para procesarlo? </h5>
                        </div>
                    
                      </ModalBody>
                      <ModalFooter>
                        <button
                          className="btn btn-dark"
                          onClick={() => setModalEditar(false)}
                        >
                          Cancelar
                        </button>
                        <button className="btn btn-info" onClick={() => peticionPut()}>
                          Reclamar
                        </button>
                      </ModalFooter>
                    </Modal>
                  </Card.Body>
                </Card>
              </Sidebar>
            </div>
          </div>
     
      );
    }
    export default Almacen;