import React, { useState, useEffect } from "react";
import Sidebar from "../components/sidebar";
import Card from "react-bootstrap/Card";
import axios from 'axios';

const url = "https://apibacked.cooltask.homes/public/usuarioscantidad";
const urldeppro = "https://apibacked.cooltask.homes/public/depositocantidad";
const urldeppen = "https://apibacked.cooltask.homes/public/depositocantidadpen";
const urlret = "https://apibacked.cooltask.homes/public/retirocantidad";

const Inicio = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);

    //////////////Datos de Usuario Logueado/////////////////////////
    const [users, setUsers] = useState([]);

  useEffect(() => {
    const loginUserJSON = window.localStorage.getItem('loginUser')
    if (loginUserJSON) {
      const user = JSON.parse(loginUserJSON)
      setUsers(user);
    }
  }, []);

  const peticionGet = async () => {
    await axios.get(url).then(response => {
      setData(response.data[0]);
     
    })
  }

  const peticionGetdepp = async () => {
    await axios.get(urldeppro).then(response => {
      setData2(response.data[0]); 
    })
  }

  const peticionGetdepp2 = async () => {
    await axios.get(urldeppen).then(response => {
      setData3(response.data[0]); 
    })
  }

  const peticionGetret = async () => {
    await axios.get(urlret).then(response => {
      setData4(response.data[0]); 
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await peticionGet();
    await peticionGetdepp();
    await peticionGetdepp2();
    await peticionGetret();
  }, []);
  
  return (
    <div>
      <div>
        <Sidebar>
          <div className="row">
            
            <div className="col-md-6">
          <Card>
            <Card.Header className="bg-dark text-white" as="h4">Usuarios registrados</Card.Header>
            <Card.Body>
              <Card.Title>usuarios</Card.Title>
              <Card.Text>
                  <h3>{data.usuarios}</h3>
              </Card.Text>
            </Card.Body>
          </Card>
          </div>
          {users.rol==='admin' &&
          <div className="col-md-6">
          <Card>
            <Card.Header className="bg-dark text-white" as="h4">Suma de los depositos realizados</Card.Header>
            <Card.Body>
              <Card.Title>Suma de los depositos realizados</Card.Title>
              <Card.Text>
              <h3>{data2.depositos} USDT</h3>
              </Card.Text>
            </Card.Body>
          </Card>
          </div>
          }
          <div className="col-md-6">
          <Card>
            <Card.Header className="bg-dark text-white" as="h4">Depositos por confirmar</Card.Header>
            <Card.Body>
              <Card.Title>Depositos por confirmar</Card.Title>
              <Card.Text>
              <h3>  {data3.depositos}</h3>
              </Card.Text>
            </Card.Body>
          </Card>
          </div>
          {users.rol==='admin' &&
          <div className="col-md-6">
          <Card>
            <Card.Header className="bg-dark text-white" as="h4">Solicitudes de Retiro</Card.Header>
            <Card.Body>
              <Card.Title>Retiros pendientes</Card.Title>
              <Card.Text>
              <h3>  {data4.retiros} </h3>
              </Card.Text>
            </Card.Body>
          </Card>
          </div>
}
          </div>
        </Sidebar>
      </div>
    </div>
  );/* }
  else{
    return (
      <div>
        <Sidebar>
          <SinPermisos/>
        </Sidebar>
      </div>
    );
  } */
};

export default Inicio;
