import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar";
import Card from "react-bootstrap/Card";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as FaIcons from "react-icons/fa";
import Cargando from "../../components/cargando";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import SinPermisos from "./../../components/sinpermisos";
import Nav from 'react-bootstrap/Nav';

const url = "https://apibacked.cooltask.homes/public/referidosconmasusuarios";
const urldelete = "https://apibacked.cooltask.homes/public/usuariosdelete";
const urleditar = "https://apibacked.cooltask.homes/public/usuariosedit";

const Referidos = () => {

  //////////////Datos de Usuario Logueado/////////////////////////
  const [users, setUsers] = useState([]);
  

  useEffect(() => {
    const loginUserJSON = window.localStorage.getItem('loginUser')
    if(loginUserJSON){
      const user= JSON.parse(loginUserJSON)
      setUsers(user);
    }
   }, []);

   console.log(users);
    

  //////////////Iniciando Status data//////////////////////////
  const [data, setData] = useState([]);

  const [datosSeleccionados, setDatosSeleccionados] = useState({
    id: '',
    nombre: '',
    username: '',
    password: '',
    wallet: '',
    calificacion: '',
    Monto: '',
    ganancias: '',
    ip: '',
    fecharegistro: '',
    hora: '',
  });

  /////////////////Datatable/////////////////////////////////

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const [pending, setPending] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(data);
      setPending(false);
    }, 100);
    return () => clearTimeout(timeout);
  });

  const columns = [
    {
      name: 'Accion',
      button: true,
      cell: (row) =>
        <Nav>
         <button  onClick={() => { setDatosSeleccionados(row); abrirModalEditaruser() }} className="btn btn-default"><FaIcons.FaRegEdit/> </button>   &nbsp;&nbsp;&nbsp;    
         <button className="btn btn-default"
         onClick={() => { setDatosSeleccionados(row); abrirModalEditar() }} 
         >
          <FaIcons.FaTrash/>
         
         </button>
           
 
       </Nav>
    },
    {
      name: 'Id',
      selector: (row) => row.id,
      defaultSortAsc: true,
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: (row) => row.nombre,
    },
    {
      name: 'Correo',
      selector: (row) => row.username,
    },
    {
      name: 'Mi Enlace',
      selector: (row) => row.micodigoinv,
    },
    {
      name: 'Ref Padre',
      selector: (row) => row.invitacion,
    },
    {
      name: 'Referidos',
      selector: (row) => row.cantidad_personas,
    },
    
    {
      name: 'Depositos de referidos',
      selector: (row) => row.suma_monto_deposito,
    },
    
  ];
  /////////////Buscar datos//////////////////////////////////
  const [buscar, setBuscar] = useState("");
  const [filtrobuscar, setFiltroBuscar] = useState([]);

  useEffect(() => {
     // eslint-disable-next-line
    const result = data.filter((resultdata) => {
      if(resultdata.username.toLowerCase().match(buscar.toLowerCase())||
      resultdata.invitacion.toLowerCase().match(buscar.toLowerCase())
      ){
        return resultdata;
      }
    });
    setFiltroBuscar(result);
  }, [buscar, data]);





  ///////////////Insertar Datos//////////////////////////////////
  

  const handleChange = e => {
    const { name, value } = e.target;
    setDatosSeleccionados(prevState => ({
      ...prevState,
      [name]: value
    }))

  }

  const [Error, setError] = useState(false);

/*   const peticionPost = async () => {
    delete datosSeleccionados.id;
    console.log(datosSeleccionados);
    await axios.post(url, datosSeleccionados).then(res => {
      peticionGet();
      setError(false);
     
    })
      .catch((err) => {
        setError(true);
        
      })
  }
 */
  //////////////Editar datos/////////////////////////////////////
  const [modalEditar, setModalEditar] = useState(false);
  const abrirModalEditar = () => {
   
    setModalEditar(true);
  }

  const peticionPut = async () => {
   
    // eslint-disable-next-line no-template-curly-in-string
    await axios.delete(urldelete + '/' + datosSeleccionados.id, datosSeleccionados).then(response => {
      peticionGet();
      setError(false);
      setModalEditar(false);
    })
      .catch((err) => {
        setError(true);
        setModalEditar(false);
      })
  }

  const [modalEditaruser, setModalEditaruser] = useState(false);
  const abrirModalEditaruser = () => {
    setModalEditaruser(true);
  }

  const peticionPutuser = async () => {
    console.log(datosSeleccionados);
    await axios.put(urleditar+'/'+datosSeleccionados.id,datosSeleccionados).then(response => {
      peticionGet();
      setError(false);
      setModalEditaruser(false);
    })
      .catch((err) => {
        console.log(err);
        setError(true);
        setModalEditaruser(false);
      })
  }

  
  /////////////Mostrar datos//////////////////////////////////
  const peticionGet = async () => {
    await axios.get(url).then(response => {
     
      setData(response.data);
      setFiltroBuscar(response.data);
      
    })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await peticionGet();
  }, []);

  /////////////////////////////////////////////////////////////////
  if(users.rol!==undefined){
    if(users.rol==='admin'){
  return (
    <div>
      <div className="Categorias">
        <Sidebar>
          <Card>
            <Card.Header className="bg-dark text-white" as="h3">
              <div className='row'>
                <div className='col-md-12'>
                   20 Usuarios con mas referidos
                </div>
               
              </div>
            </Card.Header>
            <Card.Body>
              <Card.Title> Usuarios con referidos</Card.Title>
              {
                Error &&
                <div className="alert alert-danger">
                  {<strong>Error: El nombre ya existe! intente con otro nombre.</strong>}
                </div>
              }
              <p>Estos son los 20 usuarios con mas referidos en la plataforma</p>
              <DataTable
                columns={columns}
                data={filtrobuscar}
                noDataComponent="Cargando..." 
                progressPending={pending}
                progressComponent={<Cargando />}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="Buscar Correo"
                    className="w-25 form-control"
                    value={buscar}
                    onChange={(e) => setBuscar(e.target.value)}
                  />
                }
              />

             

              <Modal isOpen={modalEditar}>
                <ModalHeader>
                  <div>
                    <h4>Eliminar usuario </h4>
                  </div>
                </ModalHeader>
                <ModalBody>
                <input
                            className="form-control" readOnly
                            type="hidden"
                            name="id"
                            value={datosSeleccionados.id}
                            onChange={onchange}
                          />
                <div className="form-group">
                          <h5>¿Desea eliminar al usuario #{datosSeleccionados.id}? </h5>
                 </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    className="btn btn-dark"
                    onClick={() => setModalEditar(false)}
                  >
                    Cancelar
                  </button>
                  <button className="btn btn-danger" onClick={() => peticionPut()}>
                    Eliminar
                  </button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={modalEditaruser}>
                      <ModalHeader>
                        <div>
                          <h5>Editar usuario {datosSeleccionados.username}</h5>
                        </div>
                      </ModalHeader>
                      <ModalBody>
                        <div className="form-group">
                          <input
                            className="form-control" readOnly
                            type="hidden"
                            name="id"
                            value={datosSeleccionados.id}
                            onChange={handleChange}
                          />

                         <label>Monto depositado</label>
                          <input
                            className="form-control"
                            type="text"
                            name="Monto"
                            value={datosSeleccionados.Monto}
                            onChange={handleChange}
      
                          />
                           <br /><br />
                          <label>Ganancias</label>
                          <input
                            className="form-control"
                            type="text"
                            name="ganancias"
                            value={datosSeleccionados.ganancias}
                            onChange={handleChange}
      
                          />
                              

      
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <button
                          className="btn btn-dark"
                          onClick={() => setModalEditaruser(false)}
                        >
                          Cancelar
                        </button>
                        <button className="btn btn-success" onClick={() => peticionPutuser()}>
                          Actualizar
                        </button>
                      </ModalFooter>
                    </Modal>
            </Card.Body>
          </Card>
        </Sidebar>
      </div>
    </div>
  );
}
else{
  return (
    <div>
    <Sidebar>
       <SinPermisos/>
     </Sidebar>  
    </div>
  );
}}
else{
  return (
    <div>
    <Sidebar>
       <SinPermisos/>
     </Sidebar>  
    </div>
  );
}
};

export default Referidos;