import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar";
import Card from "react-bootstrap/Card";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Cargando from "../../components/cargando";
import SinPermisos from "./../../components/sinpermisos";

const url = "https://apibacked.cooltask.homes/public/retirosprocesados";


const Importaciones = () => {

   //////////////Datos de Usuario Logueado/////////////////////////
   const [users, setUsers] = useState([]);
  
   

   useEffect(() => {
     const loginUserJSON = window.localStorage.getItem('loginUser')
     if(loginUserJSON){
       const user= JSON.parse(loginUserJSON)
       setUsers(user);
     }
    }, []);
     
   
  

  //////////////Iniciando Status data//////////////////////////
  const [data, setData] = useState([]);



  /////////////////Datatable/////////////////////////////////

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const [pending, setPending] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(data);
      setPending(false);
    }, 100);
    return () => clearTimeout(timeout);
  });

  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      defaultSortAsc: true,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: (row) => row.fecha,
    },
    {
      name: 'Hora',
      selector: (row) => row.hora,
    },
    {
      name: 'Cantidad',
      selector: (row) => row.cantidad,
    },
    {
      name: 'Correo',
      selector: (row) => row.correo,
    }
  ];
  /////////////Buscar datos//////////////////////////////////
  const [buscar, setBuscar] = useState("");
  const [filtrobuscar, setFiltroBuscar] = useState([]);

  useEffect(() => {
    const result = data.filter((resultdata) => {
      return resultdata.idusuario.toLowerCase().match(buscar.toLowerCase());
    });
    setFiltroBuscar(result);
  }, [buscar, data]);



  /////////////Mostrar datos//////////////////////////////////
  const peticionGet = async () => {
    await axios.get(url).then(response => {
      console.log(response.data);
      setData(response.data);
      setFiltroBuscar(response.data);
      console.log(response.data);
    })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await peticionGet();
  }, []);


  

  


  /////////////////////////////////////////////////////////////////
  if(users.rol!==undefined){
    if(users.rol==='admin'){
  return (
    <div>
      <div className="TipoAlmacen">
        <Sidebar>
          <Card>
            <Card.Header className="bg-dark text-white" as="h3">
              <div className='row'>
                <div className='col-md-6'>
                 Retiros Procesados
                </div>
              
              </div>
            </Card.Header>
            <Card.Body>
              <Card.Title>Retiros procesados registrados</Card.Title>
             
              <p>Retiros registrados en el sistema.</p>
              <DataTable
                columns={columns}
                data={filtrobuscar}
                progressPending={pending}
                progressComponent={<Cargando />}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="Buscar"
                    className="w-25 form-control"
                    value={buscar}
                    onChange={(e) => setBuscar(e.target.value)}
                  />
                }
              />

            
            </Card.Body>
          </Card>
        </Sidebar>
      </div>
    </div>
  );
 }
 else{
  return (
    <div>
    <Sidebar>
       <SinPermisos/>
     </Sidebar>  
    </div>
  );
}}
else{
  return (
    <div>
    <Sidebar>
       <SinPermisos/>
     </Sidebar>  
    </div>
  );
}
};



export default Importaciones;