import React, { useState, useEffect } from 'react';
import * as FaIcons from "react-icons/fa";
import './../assets/css/sidebar.css';
import logovefase from './../assets/logo/logo_cooltask.png';
import Accordion from 'react-bootstrap/Accordion';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const Sidebar = ({ children }) => {

    let navigate = useNavigate();

    //////////////Datos de Usuario Logueado/////////////////////////
    const [users, setUsers] = useState([]);


    useEffect(() => {
        const loginUserJSON = window.localStorage.getItem('loginUser')
        if (loginUserJSON) {
            const user = JSON.parse(loginUserJSON)
            setUsers(user);
        }
    }, []);


    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const handlelogout = () => {
        window.localStorage.removeItem('loginUser');
        navigate('/');
    }

    return (
        <div>
            {/* ---------------------------------------Navbar------------------------------------------------ */}
            <Nav className="justify-content-end Nav1" activeKey="/home">
                <Nav.Item>
                    <Nav.Link eventKey="link-1"><FaIcons.FaUserAlt />  {users.username}</Nav.Link>
                </Nav.Item>
                <Nav>
                    <NavDropdown id="nav-dropdown-dark-example" title={<FaIcons.FaRegSun />}>
                        <NavDropdown.Item><Link to="/usuarios/info" className='enlacenav'>Mis datos</Link></NavDropdown.Item>
                        <NavDropdown.Item><p className='enlacenav' onClick={() => handlelogout()}>Salir</p></NavDropdown.Item>

                    </NavDropdown>
                </Nav>


            </Nav>
            {/* ---------------------------------------Sidebar------------------------------------------------ */}
            <div className="container_side">

                <div style={{ width: isOpen ? "220px" : "60px" }} className="sidebar">
                    <div className="top_section">
                        <h1 style={{ display: isOpen ? "block" : "none" }} className="logo"><img src={logovefase} alt='some value' /></h1>
                        <div style={{ marginLeft: isOpen ? "200px" : "0px" }} className="bars">
                            <FaIcons.FaBars onClick={toggle} />
                        </div>
                    </div>
                    <div style={{ display: isOpen ? "block" : "none" }}>
                        <div style={{ background: isOpen ? "#000" : "#edf1f5" }}>
                            <Accordion className='acordion' defaultActiveKey="0" >

                                <Accordion.Item>

                                    <Link to='/inicio' className='enlace'>
                                        <Accordion.Header>
                                            <FaIcons.FaThLarge /> Inicio
                                        </Accordion.Header>
                                    </Link>

                                </Accordion.Item>
                                {users.rol==='admin' &&
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><FaIcons.FaNetworkWired />Mis trabajadores</Accordion.Header>
                                    <Accordion.Body>
                                        <Link to='/trabajadores'>
                                            ► Gestionar
                                        </Link><br></br>
                                        <Link to='/depositostodos'>
                                            ► Depositos
                                        </Link><br></br>

                                    </Accordion.Body>
                                </Accordion.Item>
                                 } 
                                 {users.rol==='admin' &&
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><FaIcons.FaUser />  Usuarios</Accordion.Header>
                                    <Accordion.Body>

                                        <Link to='/usuariossstrade/'>
                                            ► Gestionar
                                        </Link><br></br>
                                        {/* <Link to='/articulos/registrar'>
                                            ► Registrar articulos
                                        </Link><br></br>
                                        <Link to='/articulos/categorias'>
                                            ► Categorias
                                        </Link><br></br>
                                        <Link to='/articulos/tipocategorias'>
                                            ► Tipo categorias
                                        </Link><br></br>
                                        <Link to='/articulos/subcategorias'>
                                            ► Subcategoria
                                        </Link><br></br>
                                        <Link to='/articulos/plandecuentas'>
                                            ► Plan de cuentas
                                        </Link> */}

                                    </Accordion.Body>
                                </Accordion.Item>
                                  }
                                     {users.rol==='admin' &&
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header><FaIcons.FaUserFriends />Referidos</Accordion.Header>
                                    <Accordion.Body>

                                        <Link to='/referidos'>
                                            ► Tabla de Referidos
                                        </Link><br></br>
                                       
                                    </Accordion.Body>
                                </Accordion.Item>
                                  }
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><FaIcons.FaReceipt /> Depositos</Accordion.Header>
                                    <Accordion.Body>
                                        <Link to='/depositos'>
                                            ► Reclamar
                                        </Link><br></br>
                                        <Link to='/tusdepositos'>
                                            ► Tus depositos
                                        </Link><br></br>
                                        <Link to='/depositosprocesados'>
                                            ► Procesados
                                        </Link><br></br>
                                        <Link to='/depositoscancelados'>
                                            ► Cancelados
                                        </Link>

                                    </Accordion.Body>
                                </Accordion.Item>
                                 
                                 {users.rol==='admin' &&
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><FaIcons.FaMoneyBillWaveAlt /> Retiros</Accordion.Header>
                                    <Accordion.Body>
                                        <Link to='/retiros'>
                                            ► Pendientes
                                        </Link><br></br>
                                        <Link to='/retirosprocesados'>
                                            ► Procesados
                                        </Link>
                                    </Accordion.Body>
                                </Accordion.Item>
                                 }
                                {/* <Accordion.Item eventKey="5">
                                    <Accordion.Header><FaIcons.FaRegSun /> Configuracion</Accordion.Header>
                                    <Accordion.Body>
                                        <Link to='/paises'>
                                            ► Paises
                                        </Link><br></br>
                                        <Link to='/ciudades'>
                                            ► Ciudades
                                        </Link>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header><FaIcons.FaUser />Gestion de usuarios</Accordion.Header>
                                    <Accordion.Body>
                                        <Link to='/usuarios'>
                                            ► Usuarios
                                        </Link><br></br>
                                        <Link to='/usuarios/perfiles'>
                                            ► Perfiles
                                        </Link><br></br>
                                        <Link to='/usuarios/permisos'>
                                            ► Permisos
                                        </Link>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item>
                                    <Link to='/bitacora'>
                                        <Accordion.Header>
                                            <FaIcons.FaHistory /> Bitacora
                                        </Accordion.Header>
                                    </Link>
                                </Accordion.Item> */}
                                <Accordion.Item >
                                    <Accordion.Header>
                                        <p className='enlace' onClick={() => handlelogout()}>
                                            <FaIcons.FaDoorClosed /> Salir
                                        </p>
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>

                <main>{children}</main>

            </div>
        </div>
    );
};

export default Sidebar;