import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar";
import Card from "react-bootstrap/Card";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Cargando from "../../components/cargando";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

const url = "https://apibacked.cooltask.homes/public/depositosprocesados";
const urldp = "https://apibacked.cooltask.homes/public/eliminardeppro";


const TipoAlmacen = () => {

   //////////////Datos de Usuario Logueado/////////////////////////
   const [users, setUsers] = useState([]);
  

   useEffect(() => {
     const loginUserJSON = window.localStorage.getItem('loginUser')
     if(loginUserJSON){
       const user= JSON.parse(loginUserJSON)
       setUsers(user);
     }
    }, []);
     
   
  

  //////////////Iniciando Status data//////////////////////////
  const [data, setData] = useState([]);



  /////////////////Datatable/////////////////////////////////
  const [Error, setError] = useState(false);

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const [pending, setPending] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(data);
      setPending(false);
    }, 100);
    return () => clearTimeout(timeout);
  });

  const columns = [
    {
      name: 'Accion',
      button: true,
      cell: (row) =>
        <button className="btn btn-warning"
          onClick={() => { setDatosSeleccionados(row); abrirModalEditar() }} >
         Cancelar
        </button>,
    },
    {
      name: 'Id',
      selector: (row) => row.id,
      defaultSortAsc: true,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: (row) => row.fecha,
    },
    {
      name: 'Hora',
      selector: (row) => row.hora,
    },
    {
      name: 'Correo',
      selector: (row) => row.correo,
    },
    {
      name: 'Cantidad',
      selector: (row) => row.cantidad,
    },
    {
      name: 'Wallet',
      selector: (row) => row.walletorigen,
    },
    {
      name: 'TxID',
      selector: (row) => row.txid,
    },
    {
      name: 'Trabajador',
      selector: (row) => row.nombret,
    },
  ];
  /////////////Buscar datos//////////////////////////////////
  const [buscar, setBuscar] = useState("");
  const [filtrobuscar, setFiltroBuscar] = useState([]);

  useEffect(() => {
    const result = data.filter((resultdata) => {
      if(resultdata.correo.toLowerCase().match(buscar.toLowerCase())||
      resultdata.walletorigen.toLowerCase().match(buscar.toLowerCase())||
      resultdata.txid.toLowerCase().match(buscar.toLowerCase())
      ){
        return resultdata;
      }
    });
    setFiltroBuscar(result);
  }, [buscar, data]);

  
////////////////// Eliminar deposito ///////////////////

const [idd, setidd] = useState(null);

const [datosSeleccionados, setDatosSeleccionados] = useState({
  id: '',
 fecha: '',
  idusuario: '',
  cantidad: '',
  walletorigen: '',
  txid: '',
  status: '',
  idtrabajador:''
});


  const [modalEditar, setModalEditar] = useState(false);
  const abrirModalEditar = () => {
    console.log(datosSeleccionados);
    setModalEditar(true);
  }


  const peticionPut = async () => {
    console.log(datosSeleccionados);
    await axios.put(urldp+'/'+datosSeleccionados.id,datosSeleccionados).then(response => {
      peticionGet();
      setError(false);
      setModalEditar(false);
    })
      .catch((err) => {
        console.log(err);
        setError(true);
        setModalEditar(false);
      })
  }


  /////////////Mostrar datos//////////////////////////////////
  const peticionGet = async () => {
    await axios.get(url+'/'+users.id).then(response => {
      console.log(response.data);
      setData(response.data);
      setFiltroBuscar(response.data);
      console.log(response.data);
    })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await peticionGet();
  }, [users]);


  

  


  /////////////////////////////////////////////////////////////////
  return (
    <div>
      <div className="TipoAlmacen">
        <Sidebar>
          <Card>
            <Card.Header className="bg-dark text-white" as="h3">
              <div className='row'>
                <div className='col-md-6'>
                 Depositos Procesados
                </div>
              
              </div>
            </Card.Header>
            <Card.Body>
              <Card.Title>Depositos procesados registrados</Card.Title>
             
              <p>depositos registrados en el sistema.</p>
              <DataTable
                columns={columns}
                data={filtrobuscar}
                progressPending={pending}
                progressComponent={<Cargando />}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="Buscar"
                    className="w-25 form-control"
                    value={buscar}
                    onChange={(e) => setBuscar(e.target.value)}
                  />
                }
              />
               

               <Modal isOpen={modalEditar}>
                      <ModalHeader>
                        <div>
                          <h4>Reclamar deposito</h4>
                        </div>
                      </ModalHeader>
                      <ModalBody>
                          <input
                            className="form-control" readOnly
                            type="hidden"
                            name="id"
                            value={datosSeleccionados.id}
                            onChange={setidd}
                          />
                           
                        <div className="form-group">
                          <h5>¿Desea eliminar el deposito #{datosSeleccionados.id}? </h5>
                        </div>
                    
                      </ModalBody>
                      <ModalFooter>
                        <button
                          className="btn btn-danger"
                          onClick={() => setModalEditar(false)}
                        >
                          Cancelar
                        </button>
                        <button className="btn btn-info" onClick={() => peticionPut()}>
                          Eliminar
                        </button>
                      </ModalFooter>
                    </Modal>
            
            </Card.Body>
          </Card>
        </Sidebar>
      </div>
    </div>
  );
 }


export default TipoAlmacen;