import React from 'react';
import Login from './components/Login';

const App = () => {
	return (
		<Login/>
	);
} 

export default App;
