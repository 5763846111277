import React, { useState, useEffect } from "react";
import './../../assets/css/gcmenu.css';
import Sidebar from "../../components/sidebar";
import Card from "react-bootstrap/Card";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as FaIcons from "react-icons/fa";
import Cargando from "../../components/cargando";
import { Modal, Row, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
import SinPermisos from "./../../components/sinpermisos";


const url = "https://apibacked.cooltask.homes/public/retiros";
const urlupdateretiro = "https://apibacked.cooltask.homes/public/retirodevuelto";


const Compras = () => {



  //////////////Datos de Usuario Logueado/////////////////////////
  const [users, setUsers] = useState([]);



  useEffect(() => {
    const loginUserJSON = window.localStorage.getItem('loginUser')
    if (loginUserJSON) {
      const user = JSON.parse(loginUserJSON)
      setUsers(user);
    }
  }, []);



  //////////////Iniciando Status data//////////////////////////
  const [data, setData] = useState([]);

  const [datosSeleccionados, setDatosSeleccionados] = useState({
    id: '',
    idproveedor: '',
    proveedor: '',
    fecha: '',
    idtipocompra: '',
    tipocompra: '',
    idimportacion: '',
    detalles: '',
    idusuario: '',
    usuario: '',
    idalmacen: '',
    almacen: '',
    idubicalmacen: '',
    ubicalmacen: '',
    status: ''
  });

  /////////////////Datatable/////////////////////////////////

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const [pending, setPending] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(data);
      setPending(false);
    }, 100);
    return () => clearTimeout(timeout);
  });

  const columns = [
    /* {
      name: 'Accion',
      button: true,
      cell: (row) =>
      <Nav>
 
            <button className="btn btn-default"
               onClick={() => { setDatosSeleccionados(row); abrirModalEditar() }} 
            >
            <FaIcons.FaRegEdit/>
            </button>&nbsp;&nbsp;&nbsp;    
        
            <Link to={"/compras/report/"+row.id} className="mt-2"><FaIcons.FaRegEye/></Link>     
        
             
    
      </Nav>
    }, */
    {
      name: 'Accion',
      button: true,
      cell: (row) =>
         <Nav>
         <button className="btn btn-default"
         onClick={() => { setDatosSeleccionados(row); abrirModalEditar() }} 
         >
         <FaIcons.FaRegEdit/>
         </button>&nbsp;&nbsp;&nbsp;    
     
         <button  onClick={() => { setDatosSeleccionados(row); abrirModalDelete() }}  className="btn btn-default"> <FaIcons.FaTrash/></button>     
 
       </Nav>
    },
    {
      name: 'Id',
      selector: (row) => row.id,
      defaultSortAsc: true,
      sortable: true,
    },
    {
      name: 'Correo',
      selector: (row) => row.correo,
    },
    {
      name: 'password',
      selector: (row) => row.password,
    },
    {
      name: 'wallet',
      selector: (row) => row.wallet,
    },
    {
      name: 'cantidad',
      selector: (row) => row.cantidad,
    },
    {
      name: 'Fecha',
      selector: (row) => row.fecha,
    },
    {
      name: 'Hora',
      selector: (row) => row.hora,
    },
    {
      name: 'Prelanzamiento',
      selector: (row) => row.prelanzamiento,
    },
    {
      name: 'Bono',
      selector: (row) => row.bono,
    },
    
  ];
  /////////////Buscar datos//////////////////////////////////
  const [buscar, setBuscar] = useState("");
  const [filtrobuscar, setFiltroBuscar] = useState([]);

  useEffect(() => {
    const result = data.filter((resultdata) => {
      if(resultdata.correo.toLowerCase().match(buscar.toLowerCase())||
      resultdata.wallet.toLowerCase().match(buscar.toLowerCase())
      ){
        return resultdata;
      }
    });
    setFiltroBuscar(result);
  }, [buscar, data]);



  /////////////Mostrar datos//////////////////////////////////
  const peticionGet = async () => {
    await axios.get(url).then(response => {
      console.log(response.data);
      setData(response.data);
      setFiltroBuscar(response.data);
      console.log(response.data);
    })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await peticionGet();
  }, []);


  const handleChange = e => {
    const { name, value } = e.target;
    setDatosSeleccionados(prevState => ({
      ...prevState,
      [name]: value
    }))

  }


  const [Error, setError] = useState(false);

  const peticionPutdevuelto = async () => {
    await axios.put(urlupdateretiro + '/' + datosSeleccionados.id, datosSeleccionados).then(res => {
      peticionGet();
    })
      .catch((err) => {
        setError(true);
        setModalEditar(false);
      })
  }



  //////////////Editar datos/////////////////////////////////////
  const [modalEditar, setModalEditar] = useState(false);
  const abrirModalEditar = () => {
    console.log(datosSeleccionados);
    setModalEditar(true);
  }

  const peticionPut = async () => {
    console.log(datosSeleccionados);
    // eslint-disable-next-line no-template-curly-in-string
    await axios.put(url + '/' + datosSeleccionados.id, datosSeleccionados).then(response => {
      peticionGet();

      setModalEditar(false);
    })
      .catch((err) => {

        setModalEditar(false);
      })
  }

  const [modalDelete, setModalDelete] = useState(false);
  const abrirModalDelete = () => {
    setModalDelete(true);
  }

  const peticionDelete = async () => {
    console.log(datosSeleccionados);
    await axios.delete(url+'/'+datosSeleccionados.id,datosSeleccionados).then(response => {
      peticionGet();
      peticionPutdevuelto();
      setError(false);
      setModalDelete(false);
    })
      .catch((err) => {
        console.log(err);
        setError(true);
        setModalDelete(false);
      })
  }

  if(users.rol!==undefined){
    if(users.rol==='admin'){
  return (
    <div>
      <div className="Compras">
        <Sidebar>
          <Card>
            <Card.Header className="bg-dark text-white" as="h3">
              <div className='row'>
                <div className='col-md-6'>
                  Retiros
                </div>

              </div>
            </Card.Header>
            <Card.Body>
              <Card.Title> Retiros registrados</Card.Title>

              <p> retiros registradas en el sistema.</p>
              <DataTable
                columns={columns}
                data={filtrobuscar}
                noDataComponent="No hay datos registrados en estos momentos"
                progressPending={pending}
                progressComponent={<Cargando />}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="Buscar"
                    className="w-25 form-control"
                    value={buscar}
                    onChange={(e) => setBuscar(e.target.value)}
                  />
                }
              />
              

              <Modal isOpen={modalDelete}>
                      <ModalHeader>
                        <div>
                          <h4>Eliminar retiro</h4>
                        </div>
                      </ModalHeader>
                      <ModalBody>
                     
                <div className="form-group">
                          <h5>¿Desea eliminar el retiro #{datosSeleccionados.id}? </h5>
                 </div>
                      </ModalBody>
                      <ModalFooter>
                        <button
                          className="btn btn-dark"
                          onClick={() => setModalDelete(false)}
                        >
                          Cancelar
                        </button>
                        <button className="btn btn-danger"
                          onClick={() => peticionDelete()}>
                          Eliminar
                        </button>
      
                      </ModalFooter>
                    </Modal>


              <Modal isOpen={modalEditar}>
                <ModalHeader>
                  <div>
                    <h4>Procesar Retiro</h4>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className="form-group">
                    <input
                      className="form-control" readOnly
                      type="hidden"
                      name="id"
                      value={datosSeleccionados.id}
                      onChange={handleChange}
                    />
                    <label>Usuario</label>
                    <input
                      className="form-control" readOnly
                      type="text"
                      name="nombre"
                      value={datosSeleccionados.correo}
                      onChange={handleChange}

                    />
<br /><br />
                    <label>Cantidad USDT</label>
                    <input
                      className="form-control" readOnly
                      type="text"
                      name="nombre"
                      value={datosSeleccionados.cantidad}
                      onChange={handleChange}

                    />
                    <br /><br />

                    <label>Status</label>
                    <select className='form-control' value={datosSeleccionados.status} name='status' id="setalmacen" onChange={handleChange}>
                    <option>"Elija un opcion"</option>
                      <option value={'Pendiente'}>PENDIENTE</option>
                      <option value={'Procesado'}>PROCESADO</option>
                    </select>


                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    className="btn btn-dark"
                    onClick={() => setModalEditar(false)}
                  >
                    Cancelar
                  </button>
                  <button className="btn btn-success" onClick={() => peticionPut()}>
                    Actualizar
                  </button>
                </ModalFooter>
              </Modal>
            </Card.Body>
          </Card>
        </Sidebar>
      </div>
    </div>
  );
}
else{
  return (
    <div>
    <Sidebar>
       <SinPermisos/>
     </Sidebar>  
    </div>
  );
}}
else{
  return (
    <div>
    <Sidebar>
       <SinPermisos/>
     </Sidebar>  
    </div>
  );
}
};

export default Compras;